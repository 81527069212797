import { get, getAll } from './helpers.js';
import 'bootstrap/js/dist/carousel.js';
import 'bootstrap/js/dist/collapse.js'; // Used by the accordion component

let mobileNavToggle = document.getElementById('mobile-nav-toggle');
let headerNav = document.getElementById('header-nav');
mobileNavToggle.addEventListener('click', event => {
	event.preventDefault();
	headerNav.classList.toggle('open');
});


// Selects the proper carousel slide/accordion item on page load based on an anchor passed in the URL
if (location.hash !== '' && location.hash !== '#') {
	let anchor = location.hash.substr(1);
	let anchorElements = getAll(`[data-anchor-target="${anchor}"]`);

	anchorElements.forEach(element => {
		if (element.classList.contains('accordion-button')) {
			if (element.classList.contains('collapsed')) {
				// Only click accordion buttons that are collapsed
				// Prevents this from closing the accordion item that's open by default
				element.click();
			}
		} else {
			element.click();
		}
	});
}
